import { Button, Card, Divider, Group, Text, Title } from "@mantine/core";
import { openModal } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import {
  getGetApiCallGetCallListUserKey,
  postApiCallUpsertCallSubmission,
  useGetApiCallGetCallListUser,
  useGetApiOrganizationGetOrganizations,
} from "../../api/endpoints";
import RegisterOrganization from "../../components/forms/RegisterOrganization";
import Loading from "../../components/Loading";
import useSelectedOrganizationId from "../../hooks/useSelectedOrganizationId";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { useStore } from "zustand";
import { sidebarStore } from "../../components/Layout";
import { useConfig } from "../../hooks/useConfig";

function CallList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: calls, error, isLoading } = useGetApiCallGetCallListUser();
  const [selectedOrgId, setSelectedOrgId] = useSelectedOrganizationId();
  const { data: orgs } = useGetApiOrganizationGetOrganizations();
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useSelectedOrganizationId();

  const config = useConfig();
  const setSidebarOpen = useStore(
    sidebarStore,
    (state) => state.setSidebarOpen
  );

  // setSidebarOpen(true);
  async function handleSubmission(callId: string) {
    if (selectedOrgId) {
      const submission = await postApiCallUpsertCallSubmission({ callId });
      navigate(`/call/${submission.id}${submission.closed ? "/?readonly=true" : ""}`);
    } else if (orgs && orgs?.length > 0) {
      const firstOrg =
        Array.isArray(orgs) && orgs.length && typeof orgs[0] !== "undefined"
          ? orgs[0]
          : undefined;
      if (firstOrg) {
        setSelectedOrganizationId(firstOrg.id!);
      }
    } else {
      openModal({
        title: t("pages.calls.register-organization"),
        children: <RegisterOrganization></RegisterOrganization>,
      });
    }
  }
  if (error) {
    mutate(getGetApiCallGetCallListUserKey());
    console.log("mutate getGetApiCallGetCallListUserKey")

    return <Loading></Loading>;
  }
  if (!calls || isLoading) {
    return <Loading></Loading>;
  }

  return calls && Array.isArray(calls) ? (
    <>
      <Title order={1}>
        {config.listName ?? t("pages.calls.list-open-rounds")}
      </Title>
      <Divider my={25}></Divider>
      {calls
        ?.sort((a, b) => (a.name! > b.name! ? 1 : -1))
        .filter((call) => (call.name != "" && call.name != null))
        .map((call) => (
          <Card key={call.id} shadow="sm" p="md" radius="md" mb="md" withBorder>
            <Group position="apart" mb="xs">
              <Text size="lg">{call.name}</Text>

              <Text weight={400} color="dimmed">
                {t("pages.calls.status") + " "}
                {call.closingAt &&
                  (Date.now() < Date.parse(call.closingAt)
                    ? t("pages.calls.open")
                    : t("pages.calls.close"))}
              </Text>
            </Group>

            <div dangerouslySetInnerHTML={{ __html: call.description! }}></div>

            <Divider mt="lg"></Divider>
            <Button
              variant="light"
              color={call.submissionClosed ? "green" : "blue"}
              mt="sm"
              radius="sm"
              style={{ float: "right" }}
              onClick={async () => await handleSubmission(call.id!)}
              disabled={orgs?.length == 0 || ( Date.now() > Date.parse(call.closingAt!))}
            >
              {call.submissionClosed
                ? t("pages.calls.closed")
                : call.submissionId != "00000000-0000-0000-0000-000000000000"
                ? t("pages.calls.resume")
                : t("pages.calls.submit")}
            </Button>
          </Card>
        ))}
    </>
  ) : null;
}

export default CallList;
